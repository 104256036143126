.fontwww {
    font-weight: bold;
}

.fontwww1 {
    font-size: 10px;
    color: #AEAEAE;
    border: 1px solid #AEAEAE;
    padding: 0.5px 5px;
    margin-left: 10px;

}

.table-row-div-1 {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}