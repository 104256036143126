.basic-requirement-container{
  width: 100%;
  // height: 100%;
  min-height: 100%;
  max-height: auto;
  padding:30px 60px;
  background-color: #fff;
  display: flex;
  .basic-requirement-container-left{
    width: 80%;
    color: #000;
    font-size: 14px;
    .basic-requirement-container-left-title {
      padding-left:23px;
      color: #5F5F5F;
      font-family: Microsoft YaHei UI;
      font-weight: 400;
      line-height: 24px;
      display: flex;
      align-items: center;
    }
    .basic-requirement-container-left-small {
      padding-left:46px;
      font-size: 12px;
      font-family: Microsoft YaHei UI;
      font-weight: 400;
      color: #999999;
      line-height: 24px;
      .small-span{
        margin-left: 8px;
      }
    }
    .small-title{
      margin-top:11px;
      margin-bottom:11px;
    }

    .basic-requirement-container-left-item{

      .basic-requirement-container-left-item-title{
        font-size: 14px;
        font-family: Microsoft YaHei UI;
        font-weight: bold;
        color: #262626;
        line-height: 24px;
      }
    }
  }
  .basic-requirement-container-right{
    width: 20%;
    .acher-container{
      background: #FFFFFF;
      border: 1px solid #ECECEC;
      box-shadow: 0px 0px 20px 0px rgba(77, 77, 77, 0.15);
      border-radius: 6px;
      width: 180px;
    }
  }

  .basic-requirement-foot{
    text-align: right;
    padding-top:20px;
    position: absolute;
    z-index: 99;
    bottom: 80px;
    right: 80px;
    Button{
      margin-left: 16px;
    }
  }
}

.right-anchor-box{
  width: 186px;
  
  .anchor-title-img{
    position: absolute;
    top: 15px;
    z-index: 1000;
  }

  .anchor-body-box{
    width: 178px;
    height: 305px;
    background: #FFFFFF;
    border: 1px solid #ECECEC;
    box-shadow: 0px 0px 20px 0px rgba(77, 77, 77, 0.15);
    border-radius: 6px;
    margin: 0px 4px;
    padding-top: 60px;

    .link-item{
      font-size: 14px;
      font-family: Microsoft YaHei UI;
      font-weight: bold;
      // color: #262626;
      line-height: 24px;
    }
  }
}

div.ant-anchor-wrapper{
  margin-left: 0px !important;
  padding-left: 0px !important;
}

div.ant-anchor-ink{
  position: relative;
}

div.ant-anchor-link{
  padding: 5px 0px 5px 16px;
}

.ant-anchor-link-active > .ant-anchor-link-title{
  color: #00A968 !important;
}

a:hover{
  color: #00A968 !important;
}