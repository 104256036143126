.result-container{
  width: 100%;
  background-color: #fff;
  padding:24px 32px;
  .result-calculation{
    width: 100%;
    height: 72px;
    display: flex;
    justify-content: space-between;
    border: 1px solid #E9E9E9;
    align-items: center;
    padding:0 32px;
  }
  .table-container{
    margin-bottom: 32px;
    p{
      color:#4D4D4D;
      font-size: 14px;
    }
  }
  .button-container{
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .row-uts{
    background-color: #DCF2DF !important;
  }
  .row-ets{
    background-color: #E8D1D1 !important;
  }
}
