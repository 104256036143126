
.dashboard-background {
  background: #fff;
  // background-image: url('/assets/images/dashboard.png');
  display: flex;
  align-items: center;

  .select-system-container {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .select-system-item {
      width: 22rem;
      height: 25rem;
      background: #F7FBFA;
      border: 1px solid #83D7B7;
      box-shadow: 0px 0px 9px 0px #B8EFDB;
      opacity: 0.9;
      border-radius: 4px;
      padding: 3.25rem 0 5.75rem 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      & > div {
        font-size: 1.5rem;
        color: #262626;
        font-weight: bold;
      }
    }
  }
}

.dashboard-container {
  border-radius: 4px;
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: space-around;

  .dashboard-container-item {
    height: 100%;
    width: 100%;
    border-radius: 6px;
    opacity: 0.5;
    align-items: center;
    justify-content: center;

    .dashboard-container-box {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .ant-card-bordered {
        border: 0;
      }

      .ant-card {
        background: transparent;
      }

      .card-meta {
        display: flex;
        justify-content: center;

        .card-meta-item {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          border-radius: 22px;
          height: 2.75rem;
          //min-width: 13.75rem;
          //max-width: 100%;
          padding: 0px 50px;
          box-sizing: border-box;

          .card-meta-span {
            font-size: 1.125rem;
            font-family: Microsoft YaHei UI;
            font-weight: bold;
            color: #FFFFFF;
            padding-right: 0.5rem;

          }
        }

        .card-description {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 0.75rem;
          font-family: Microsoft YaHei UI;
          font-weight: 400;
          color: #858585;
          line-height: 1.125rem;
          padding-top: 0.5rem;

          .card-description-span {
            width: 90%;
            text-align: center;
            white-space: normal;
          }
        }
      }

    }

  }

  .dashboard-container-item:hover {
    opacity: 1 !important;
  }

}

.bg1 {
  background: linear-gradient(180deg, #56a185 0%, #FFFFFF 100%);
}

.bg2 {

  background: linear-gradient(0deg, #4f93b2 0%, #FFFFFF 100%);
}

.bg3 {

  background: linear-gradient(180deg, #65a5a9 0%, #FFFFFF 100%);
}

.bg4 {

  background: linear-gradient(0deg, #6873b7 0%, #FFFFFF 100%);
}

.bg5 {

  background: linear-gradient(180deg, #4f93b2 0%, #FFFFFF 100%);
}

.ant-card-body {
  margin-top: 30px;
  padding: 0;
}

.imhase {
  width: auto;
  height: auto;
}

//.div-hover:hover {
//  cursor: not-allowed !important;
//  pointer-events: none !important;
//}
