.diagnosis-list{
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding:32px 40px;
  overflow: auto;
}
.expert-drawer-search{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.green-button{
  background-color: #0F9940 !important;
  color: #fff !important;
  font-size: 14px;
  border:1px solid #0F9940 !important;
}

.expert-drawer-search-new{
  display: flex;
  align-items: center;
}

.download-box{
}