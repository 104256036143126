.annual-process-container{
  width: 100%;
  background-color: #fff;
  padding:32px 32px;
  margin-bottom: 30px;
  .annual-process-item{
    width: 100%;
    margin-bottom:20px;
    border-top:1px dashed #D9D9D9;
  }
}
.system-boundary-item-head-name{
  height: 40px;
  display: flex;
  align-items: center;
  font-weight: bold;
}
.annual-process-table-head{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top:30px;
  .annual-process-table-title{
    color: #737373;
    font-weight: bold;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    width: 30%;
    margin-bottom:10px;
  }
}
.waste-heat-container{
  margin-top:15px;
  .waste-heat-head{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top:10px;
    margin-bottom: 10px;
  }
}
