.head{
  width:100%;
  height:56px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:0 24px;
}
.nav{
  height: 56px;
  display: flex;
  align-items: center;
  .logo{
    width: 100px;
    height: 47px;
    margin-right:110px;
    cursor: pointer;
  }
  .title{
    color:#07B06F;
    font-size: 18px;
    font-weight: bold;
    margin-left:8px;
    margin-right:23px;
  }
}
.ant-menu-horizontal{
  border-bottom: none !important;
}
.ant-menu-horizontal > .ant-menu-item-selected{
  border-bottom-color: #26B57A !important;
  color:#26B57A !important;
}
.ant-menu-horizontal > .ant-menu-item-active{
  border-bottom-color: #26B57A !important;
  color:#26B57A !important;
}
.head-right{
  display: flex;
  align-items: center;
  .head-right-item{
    display: flex;
    align-items: center;
    margin-left:20px;
    // color: #ccc;
    cursor: pointer;
    span{
      margin-left:8px;
    }
  }
}
.ant-dropdown-link{
  cursor: pointer;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after{
  border-bottom:2px solid #26B57A !important;
}