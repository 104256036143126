.product-info-detail-saving-information{
  width: 100%;
  height: 100%;
  overflow-y: auto;
  color: #262626;
  font-size: 14px;
  padding:8px 16px;
  background-color: #fff;
  .row-bottom{
    margin-bottom: 40px;
  }
  .ant-checkbox-disabled + span{
    color: #262626;
  }

  .tabs-header{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .columns-delete{
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding-right: 32px;
    width: 220px;
  }
  .tag-box{
    color: #AEAEAE;
    border: 1px solid #D9D9D9;
  }

  .div-box-span-color1{
    width: 20px;
    height: 10px;
    background: #cceee1;
    // opacity: 0.2;
    display: inline-block;
    margin-right: 8px;
  }
  .div-box-span-color2{
    width: 20px;
    height: 10px;
    background: #f6dddd;
    // opacity: 0.2;
    display: inline-block;
    margin-right: 8px;
  }

  .rowColor1{
    background-color: #f5fcf9;
    // background: #00A968;
    // opacity: 0.04;
  }
  .rowColor2{
    background-color: #fdf8f8;
    // background: #D15757;
    // opacity: 0.04;
  }
  .img{
    cursor: pointer;
  }
}

.header-title{
  font-size: 12px;
  font-family: Microsoft YaHei UI;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
  padding-left: 8px;
}

.calculate-input{
  font-family: Microsoft YaHei UI;
  font-weight: bold;
  color: #666666;
}

.zb-font {
  font-size: 14px;
  font-family: Microsoft YaHei UI;
  font-weight: 400;
  color: #00A968;
}

input#product-data_firstItem3.ant-input-disabled{
  background: none !important;
  cursor: auto;
}
input#product-data_firstItem5.ant-input-disabled{
  background: none !important;
  cursor: auto;
}
input#product-data_firstItem11.ant-input-disabled{
  background: none !important;
  cursor: auto;
}
input#product-data_firstItem12.ant-input-disabled{
  background: none !important;
  cursor: auto;
}
input#product-data_secondItem3.ant-input-disabled{
  background: none !important;
  cursor: auto;
}
input#product-data_secondItem5.ant-input-disabled{
  background: none !important;
  cursor: auto;
}
input#product-data_secondItem11.ant-input-disabled{
  background: none !important;
  cursor: auto;
}
input#product-data_secondItem12.ant-input-disabled{
  background: none !important;
  cursor: auto;
}
input#product-data_threeItem3.ant-input-disabled{
  background: none !important;
  cursor: auto;
}
input#product-data_threeItem5.ant-input-disabled{
  background: none !important;
  cursor: auto;
}
input#product-data_threeItem11.ant-input-disabled{
  background: none !important;
  cursor: auto;
}
input#product-data_threeItem12.ant-input-disabled{
  background: none !important;
  cursor: auto;
}

.item-title-p{
  display: inline-block;
  font-size: 14px;
  font-family: Microsoft YaHei UI;
  font-weight: bold;
  color: #262626;
  line-height: 24px;
}

.item-title-divider{
  width: 3px;
  background: #262626;
  margin-left: 0px !important;
  display: inline-block;
}

.item-div-popover-title{
  display: inline-block;
  width: 100%;
  height: 16px;
  font-size: 16px;
  font-family: Microsoft YaHei UI;
  font-weight: bold;
  color: #262626;
  line-height: 24px;
  text-align: center;
}
