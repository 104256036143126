.enter-prise-level-display-container{
  width: 100%;
  background-color:#F0F2F5;
  .enter-prise-level-display-container-item{
    width: 100%;
    background: #fff;
    margin-bottom: 0.7rem;
    .enter-prise-level-display-container-item-head{
      width: 100%;
      height: 3rem;
      border-bottom:1px solid #d9d9d9;
      line-height: 3rem;
      padding-left: 2.5rem;
      font-weight: bold;
      color: #333;
      font-size: 0.875rem;
    }
    .echart-tag{
      padding:0 1rem;
      background:linear-gradient(-90deg, #26B57A, #5FD7A6);
      color: #fff;
      font-size: 0.75rem;
      border-top-right-radius: 1rem;
      border-bottom-right-radius: 1rem;
      height: 2rem;
      line-height: 2rem;
      display: inline-block;
      margin-bottom: 1rem;
      margin-left: 2rem;
      margin-top: 2rem;
    }
    .enter-prise-level-display-container-item-body{
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding-top: 2rem;
      padding-bottom: 2rem;
      .enter-prise-level-display-container-item-body-left{
        width: 40%;
        height: 20rem;
      }
      .enter-prise-level-display-container-item-body-right{
        width: 40%;
        .enter-prise-level-display-container-item-body-right-container{
          width: 100%;
          .enter-prise-level-display-container-item-body-right-container-item{
            width: 100%;
            padding-top:0.8125rem;
            padding-bottom:0.8125rem;
            display: flex;
            justify-content: space-between;
            font-size: 0.875rem;
            color: #666;
            border-bottom:1px solid #d9d9d9;
          }
          .enter-prise-level-display-container-item-body-right-container-item:nth-child(1){
            border-bottom:3px solid #d9d9d9;
          }
        }
      }
    }
  }
}