.diagnosis-process-container{
  width: 100%;
  height: 100%;
  padding:8px 20px;
  overflow: auto;
  display: flex;
  .left-nav{
    width: 15%;
    height: 100%;
    .step-value-width{
      width: 200px !important;
      height: 460px;
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after{
      background-color:#D9D9D9;
    }
    .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after{
      background-color:#D9D9D9;
    }
  }
  .right-container{
    width: 85%;
    height: 100%;
    .head-nav{
      width: 100%;
      height: 52px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #fff;
      border-bottom: 1px solid #D9D9D9;
      .step{
        width:100%;
        overflow-x: auto;
        display:flex;
        align-items: center;
        .ant-divider-vertical{
          height: 2.5em;
          border-color:#E8E8E8;
        }
        .ant-steps-item-process .ant-steps-item-icon{
          background:#00A566;
        }
        .ant-steps-item-finish .ant-steps-item-icon{
          border-color: #00A566;
        }
        .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon{
          color:#00A566;
        }
        .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-title{
          color:#00A566;
        }
      }
      .step-name{
        width:150px;
        font-size: 14px;
        color:#262626;
        font-weight: bold;
        padding: 0 32px;
      }
      .step-container{
        width: 85%;
        overflow: auto;
        height: 52px;
        .site-navigation-steps-value{
          width: 100% !important;
        }
      }
      // .step-container::-webkit-scrollbar{
      //   display: none;
      // }
    }
    .body-container{
      width: 100%;
      height: 100%;
      overflow-y: auto;
      .ant-table-container{
        .ant-empty-normal {   
          margin: 0px 0 !important;
         }
         .ant-empty-normal .ant-empty-image{
         display: none !important;
         }
      }
      .show-echants-rect{
        width: 3rem;
        height: 3rem;
        background-color: #ececec;
        border-radius: 100%;
        position: fixed;
        display:flex;
        justify-content: center;
        align-items: flex-end;
        padding-bottom: 1rem;
        cursor: pointer;
        right: 5rem;
        bottom:3rem;
        div{
          width: 0.375rem;
          height: 0.75rem;
        }
        div+div{
          margin-left: 0.18rem;
        }
        div:nth-child(1){
          background-color: #2A53D8;
        }
        div:nth-child(2){
          height: 1.25rem;
          background-color: #EFBA1E;
        }
        div:nth-child(3){
          height: 1rem;
          background-color: #2A53D8;
        }
      }
    }
  }
  
}
.green-button{
  background-color: #0F9940 !important;
  color: #fff !important;
  font-size: 14px;
  border:1px solid #0F9940 !important;
}
.green-table-a{
  color:#0F9940;
  cursor: pointer;
  font-size: 14px;
}
.icon-style{
  cursor: pointer;
}
.application-energy{
  width: 100%;
  padding:30px 30px;
  background-color: #fff;
  overflow-x: auto;
  .application-energy-head{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .application-energy-head > div{
      font-size: 15px;
      color:#737373;
      font-weight: bold;
    }
  }
}
.site-navigation-steps{
  width: 1600px !important;
}
.ant-steps-navigation{
  padding-top: 8px !important;
}

.step-container::-webkit-scrollbar {/*滚动条整体样式*/
  width: 10px;     /*高宽分别对应横竖滚动条的尺寸*/
  height: 8px;
}

.step-container::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
  border-radius: 10px;
  background: #989898;
}

.step-container::-webkit-scrollbar-track {/*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 5px #f4f4f4;
  border-radius: 10px;
  background: #EDEDED;
}

.table-container{
  width: 100%;
  height: 100%;
  padding:32px 32px;
  background-color: #fff;
  .table-header{
    width: 100%;
    height:40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    .table-title{
      font-size: 15px;
      color:#737373;
      font-weight: bold;
    }
    .table-add-btn{
      padding:5px 17px;
      color: #fff;
      background-color: #00A566;
      font-size: 14px;
      cursor: pointer;
    }
  }
  .pagination{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top:30px;
  }
}
.modular-title{
  font-size:15px;
  font-weight: bold;
}
.ant-divider-horizontal{
  margin: 12px 0 !important;
}