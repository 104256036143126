.diagnosis-container{
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding:32px 40px;
  overflow: auto;
}
.green-table-a{
  color:#0F9940;
  cursor: pointer;
  font-size: 14px;
}
.pagination{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top:30px;
}
