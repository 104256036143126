.summarized-result-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  color: #262626;
  padding: 30px 50px 60px 50px;
  background-color: #fff;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;

  .header-title {
    font-size: 24px;
    font-family: Microsoft YaHei UI;
    font-weight: 400;
    color: #262626;
    line-height: 24px;
    text-align: center;
  }

  .card-div-Row {
    width: 100%;
    box-shadow: 0px 0px 13px 0px rgba(46, 46, 46, 0.08);
    border-radius: 4px;
    min-height: 208px;
    margin: 0px 0px 40px 0px !important;
    padding: 20px 32px;


    .card-div-item-title {
      font-size: 16px;
      font-family: Microsoft YaHei UI;
      font-weight: 400;
      color: #262626;
      line-height: 24px;
    }

    .card-div-Col {
      padding: 0px 32px;
      display: flex;
      justify-content: center;

      .card-div-width80 {
        width: 80%;
      }

      .card-item-14 {
        font-size: 14px;
        font-family: Microsoft YaHei UI;
        font-weight: 400;
        color: #999999;
        line-height: 24px;
      }

      .card-item-10 {
        font-size: 10px;
        font-family: Microsoft YaHei UI;
        font-weight: 400;
        color: #999999;
        line-height: 24px;
      }
    }
  }

  .card-div-title {
    font-size: 18px;
    font-weight: bold;
    font-family: Microsoft YaHei UI;
    color: #262626;
    line-height: 24px;
    // width: 100%;
    display: inline-block;
  }


  .card-bais-content {
    font-size: 12px;
    font-family: Microsoft YaHei UI;
    font-weight: 400;
    color: #5F5F5F;
    line-height: 18px;
    padding: 20px 0px;
  }

  .card-bais-box {
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 13px 0px rgba(46, 46, 46, 0.08);
    border-radius: 4px;
    padding: 16px 32px;
  }

  .card-bais-div {
    width: 100%;
    height: calc(100% - 56px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card-bais-Col {
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    font-family: Microsoft YaHei UI;
    color: #999999;
    line-height: 24px;
  }

  .card-bais-img {
    font-size: 16px;
    font-family: Microsoft YaHei UI;
    font-weight: 400;
    color: #999999;
    padding-left: 8px;
  }

  .font-Red {
    font-size: 16px;
    font-family: Microsoft YaHei UI;
    font-weight: bold;
    color: #D15757;
  }

  .font-Green {
    font-size: 16px;
    font-family: Microsoft YaHei UI;
    font-weight: bold;
    color: #00A968;
  }
}

.item-title-divider {
  width: 3px;
  height: 16px !important;
  background: #262626;
  margin-left: 0px !important;
  display: inline-block;
}

.table-title {
  font-size: 14px;
  font-family: Microsoft YaHei UI;
  font-weight: 400;
  color: #999999;
  line-height: 24px;
}

.table-row {
  font-size: 14px;
  font-family: Microsoft YaHei UI;
  font-weight: 400;
  color: #262626;
  line-height: 24px;
}
