.energy-management-container{
  width: 100%;
  padding:30px 60px;
  background-color: #fff;
  display: flex;
  .energy-management-container-left{
    width: 80%;
    color: #000;
    font-size: 14px;
    .energy-management-container-left-default {
      padding-left:23px;
      color:#000;
    }
    .energy-management-container-left-small {
      padding-left:23px;
      margin-top: 15px;
    }
    .small-title{
      margin-top:11px;
      margin-bottom:11px;
    }
  }
  .energy-management-container-right{
    width: 20%;
  }
}