.summarized-result-container {
    display: flex;
    flex-direction: column;

    .header-title {
        height: 25px;
        font-size: 24px;
        font-weight: bold;
        color: #262626;
        line-height: 24px;
    }

    .resuits {
        margin-top: 117px;

        display: flex;
        justify-content: space-around;

        .resuits1 {
            position: relative;

            .resuits12 {
                width: 200px;
                position: absolute;
                top: 5%;
                left: 20%;
                text-align: center;

                >div {
                    width: 100%;
                    align-items: center;
                    font-size: 18px;
                    font-weight: bold;
                    color: #262626;
                    line-height: 24px;
                }

                >span {
                    width: 100%;
                    align-items: center;
                    display: inline-block;
                    font-size: 56px;
                    font-weight: 700;
                    line-height: 50px;
                }
            }
        }

        .resuits2 {
            width: 40%;



            .resuits223 {
                margin-bottom: 25px;

                .resuits221 {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                }
            }

            .resuis {
                width: 100%;
                display: flex;
                align-items: center;

                .resioss {
                    width: 100%;
                }
            }
        }
    }

    .m-r10 {
        margin-right: 10px;
    }

    .fontSzi {
        font-size: 16px;
        font-weight: bold;
        color: #262626;
    }

    .fontSzimin {
        font-size: 12px;
        font-weight: 400;
        color: #999999;

    }

    .fontfais {
        width: 100%;
        margin: 0px 24px 0px 24px;
        border: 1px solid #E9E9E9;
        padding: 10px;

        .fonttext {
            font-size: 12px;
            font-weight: 400;
            color: #5F5F5F;
        }
    }

    .resuits21 {
        margin-bottom: 35px;
    }

}