.application-energy{
  width: 100%;
  padding:30px 30px;
  background-color: #fff;
  overflow-x: auto;
  .application-energy-head{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .application-energy-head > div{
      font-size: 14px;
      color:#4d4d4d;
      font-weight: bold;
    }
  }
}
.div-a{
  color: #4E8AFA;
  cursor: pointer;
}