.table-title{
  width: 100%;
  font-size: 14px;
  font-family: Microsoft YaHei UI;
  font-weight: bold;
  color: #262626;
  line-height: 24px;
}
.table-header-content{
  width: 100%;
  font-size: 12px;
  font-family: Microsoft YaHei UI;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}

.button-margin-left{
  position: absolute;
  right: 8px;
  bottom: 0;
}
.div-a{
color: #00A968;
cursor: pointer;
}