.water-effect-saving-information{
    width: 100%;
    height: 100%;
    overflow-y: auto;
    color: #262626;
    font-size: 14px;
    padding:32px 32px;
    background-color: #fff;
    .row-bottom{
      margin-bottom: 40px;
    }
    .ant-checkbox-disabled + span{
      color: #262626;
    }
  }
  
  .ant-upload-list-item-info > span{
    //display: inline-block !important;
    //width: auto !important;
  }
  
  .ant-upload-list-item-card-actions{
    position: absolute;
    right: unset !important;
  }