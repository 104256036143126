.declaration-background{
  width: 100%;
  background: #fff;
  padding:1.5rem 2.5rem;
  .declaration-title{
    font-size: 1.125rem;
    color: #262626;
    font-weight: bold;
    margin-bottom: 1.75rem;
  }
}

.img-title{
  height: 1.25rem;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 1.125rem;
  color: #262626;
  img{
    margin-right: 0.5rem;
  }
}

.info-item{
  margin-bottom: 1.8rem;
  padding-left: 1.8rem;
  & .info-item-title{
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    & span{
      font-size: 1rem;
      color: #000;
      font-weight: bold;
    }
  }
  & .info-item-container{
    padding-left: 1.675rem;
  }
}

.file-container{
  display: flex;
  align-items: center;
}

.file-item{
  width: 8.125rem;
  height: 8.75rem;
  background: #eff8f5;
  border: 1px dashed #07B06F;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 2.43rem;
  font-size: 0.75rem;
  cursor: pointer;
  &>div:nth-child(1){
    margin-top: 2rem;
  }
}
.add-form{
  width: 5.75rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  border: 1px solid #00A566;
  color: #00A566;
  font-size: 0.875rem;
  cursor: pointer;
  margin-left: 2.75rem;
}