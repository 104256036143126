.expert-score{
  display: inline-block;
  padding-left: 10px;
}
.expert-score-group{
  display: flex;
  align-items: center;
}
.expert-right{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}


