.effect-assess-list{
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding:32px 40px;
  overflow: auto;
}

.effect-assess-list-status-item{
  width:6rem;
  height: 2rem;
  line-height: 2rem;
  background-color: #D4D9D8;
  text-align: center;
  color: #000;
  cursor: pointer;
}
.effect-assess-list-status-item-active{
  background-color: #000 !important;
  color: #fff !important;
}

.modal-container{
  width: 100%;
  height: 410px;
  .modal-effect-assess-item{
    margin-bottom:30px;
    .ant-checkbox-disabled + span{
      color: #262626;
    }
  }
}

.green-table-a{
  color:#0F9940;
  cursor: pointer;
  font-size: 14px;
}

.pagination{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top:30px;
}

.diagnosis-list-status-item-active{
  background-color: #000 !important;
  color: #fff !important;
}
.ant-input-disabled,.ant-input-number-disabled,.ant-picker-disabled,.ant-cascader-picker-disabled{
  color: rgba(21,19,19,0.7) !important;
  background-color: #fbfbfb !important;
}
.ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
  color: rgba(21,19,19,0.7) !important;
  background-color: #fbfbfb !important;
}
.ant-radio-disabled + span {
  color: rgba(21,19,19,0.7) !important;
}
.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after{
  border-color:  rgba(21,19,19,0.7) !important;
}
.ant-select-disabled.ant-select-multiple .ant-select-selector{
  color: rgba(21,19,19,0.7) !important;
  background-color: #fbfbfb !important;
}
.ant-picker-input > input[disabled]{
  color: rgba(21,19,19,0.7) !important;
  background-color: #fbfbfb !important;
}
