
.add-user{
  h3{
    font-weight: bold;
  }
  .ant-radio-button-wrapper{
    padding:0 32px !important;
  }
  .ant-form-item{
    margin-bottom: 12px !important;
  }
}