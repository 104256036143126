.basic-requirement-container{
    width: 100%;
    // height: 100%;
    min-height: 100%;
    max-height: auto;
    padding:30px 60px;
    background-color: #fff;
    display: flex;
    .basic-requirement-container-left{
      width: 100%;
      color: #000;
      font-size: 14px;
      .basic-requirement-container-left-default {
        padding-left:23px;
        color:#000;
      }
      .basic-requirement-container-left-small {
        padding-left:23px;
        font-size: 12px;
        font-family: Microsoft YaHei UI;
        font-weight: 400;
        color: #999999;
        line-height: 24px;
        .small-span{
          margin-left: 8px;
        }
      }
      .small-title{
        margin-top:11px;
        margin-bottom:11px;
      }
    }
    .basic-requirement-container-right{
      width: 20%;
    }

    .basic-requirement-foot{
      text-align: right;
      padding-top:20px;
      Button{
        margin-left: 16px;
      }
    }
  }
  .expert-score-group{
    display: flex;
    align-items: center;
    .expert-score{
      display: inline-block;
      padding-left: 10px;
    }
  }

.ffims-one{
margin-bottom: 40px;
    display: flex;
    .ant-upload-list{

        position: relative;
       left: -300px;
       bottom: 0;
       top: 10px;
       right: 0;
    white-space:nowrap

    }
    >.ant-form-item{
        width: 300px;
        margin-bottom: 0px;
    }
.ant-input-number{
        width: 260px;
    }
}
.ant-popover-inner-content{
  width: 500px;
}
  