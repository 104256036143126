$marginLength: 1000;
$widthAndHeightBoundary: 2000;
@for $i from 1 through $marginLength{
  .margin-top-#{$i} { margin-top:($i/16)+rem }
  .margin-right-#{$i} { margin-right:($i/16)+rem }
  .margin-bottom-#{$i} { margin-bottom:($i/16)+rem }
  .margin-left-#{$i} { margin-left:($i/16)+rem }

  .padding-top-#{$i} { padding-top:($i/16)+rem }
  .padding-right-#{$i} { padding-right:($i/16)+rem }
  .padding-bottom-#{$i} { padding-bottom:($i/16)+rem }
  .padding-left-#{$i} { padding-left:($i/16)+rem }
}

@for $i from 1 through $widthAndHeightBoundary{
  .width-#{$i} { width:($i/16)+rem }
  .height-#{$i} { height:($i/16)+rem }
  .div-flex-#{$i} { @extend .width-#{$i}; display: flex; }
}

.align-item{
  &-center{
    align-items: center;
  }
  &-flex-start{
    align-items: flex-start;
  }
  &-flex-end{
    align-items: flex-end;
  }
}

.justify-content{
  &-center{
    justify-content: center;
  }
  &-flex-start{
    justify-content: flex-start;
  }
  &-flex-end{
    justify-content: flex-end;
  }
  &-space-between{
    justify-content: space-between;
  }
  &-space-around{
    justify-content:space-around;
  }
  &-space-evenly{
    justify-content:space-evenly;
  }
}