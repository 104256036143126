.declaration-background {
  width: 100%;
  background: #fff;
  padding: 1.5rem 2.5rem;

  .declaration-title {
    font-size: 1.125rem;
    color: #262626;
    font-weight: bold;
    margin-bottom: 1.75rem;
  }
}

.img-title {
  height: 1.25rem;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 1.125rem;
  color: #262626;

  img {
    margin-right: 0.5rem;
  }
}


.info-item {
  margin-bottom: 1.8rem;
  padding-left: 1.8rem;

  & .info-item-title {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;

    & span {
      font-size: 1rem;
      color: #000;
      font-weight: bold;
    }
  }

  & .info-item-container {
    padding-left: 1.675rem;
  }
}


.add-form {
  width: 5.75rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  border: 1px solid #00A566;
  color: #00A566;
  font-size: 0.875rem;
  cursor: pointer;
  margin-left: 2.75rem;
}

.comitsBox {
  .ant-form-item-label {
    width: 80px;
  }

  .ant-col-6 {

    max-width: 100%;
  }

  .ant-upload {
    margin-right: 0px;
  }
}

.uobox {
  border: 1px solid #0F9940;

  .file-container {
    display: flex;
    align-items: center;
    margin-left: 0px !important;

    & > span:nth-child(1) {
      margin-right: 10px;
    }

    .file-item {
      width: 12.125rem;
      height: 8.75rem;
      background: #eff8f5;
      border: 1px dashed #07B06F;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 0.75rem;
      cursor: pointer;
      margin-right: 0;


      & > div:nth-child(1) {
        margin-top: 2rem;
      }


    }

  }


}

.uobox1 {
  border: 1px solid #0F9940;

  .file-container {
    display: flex;
    align-items: center;
    margin-left: 0px !important;

    & > span:nth-child(1) {
      margin-right: 10px;
    }

    .file-item {
      width: 12.125rem;
      height: 8.75rem;
      background: #eff8f5;
      border: 1px dashed #07B06F;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 0.75rem;
      cursor: pointer;
      margin-right: 0;


      & > div:nth-child(1) {
        margin-top: 2rem;
      }


    }

  }

}


.w100 {
  width: 100%;
}

.flexmax {
  display: flex;
}

.jusconr {
  justify-content: center;
}

.jusswb {
  justify-content: space-between;
}

.alinconter {
  align-items: center;

}

.prosn50 {
  width: 50%;

}

.namensabus {
  position: relative;

  .namensabus-text {
    font-size: 16px;
    font-family: Microsoft YaHei UI;
    font-weight: bold;
    color: #07B06F;
    position: absolute;
    top: 0;
    left: 27%;
    right: 0;
    bottom: 0;
  }

  .namensabus-text1 {
    font-size: 16px;
    font-family: Microsoft YaHei UI;
    font-weight: bold;
    color: #7F7F7F;
    position: absolute;
    top: 0;
    left: 27%;
    right: 0;
    bottom: 0;
  }
}

