.five-step-container{
  width: 100%;
  padding:1rem 6rem;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .five-step-container-item{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .five-step-container-item-left{
      width: 48%;
      display: flex;
      flex-direction: column;
      .five-step-container-item-left-title{
        width: 100%;
        font-size: 1.625rem;
        color: #458755;
        margin-bottom: 1rem;
        img{
          margin-right: 0.75rem;
        }
      }
      .five-step-container-item-left-introduction{
        width: 100%;
        color: #458755;
        font-size: 0.875rem;
        margin-bottom: 1rem;
      }
      .five-step-container-item-left-lvl{
        width: 100%;
        font-size: 1rem;
        color: #333;
        font-weight: bold;
        margin-bottom: 0.75rem;
      }
      .five-step-container-item-left-option{
        width: 100%;
        font-size: 0.85rem;
        .five-step-container-item-left-option-item{
          color: #333;
          margin-bottom:0.5rem;
          .five-step-container-item-left-option-item-container{
            padding-left: 1.25rem;
          }
        }
      }
      .division{
        width: 80%;
        height: 1px;
        background-color: #a9a7a7;
        margin-top:0.625rem;
        margin-bottom: 1rem;
      }
      .five-step-container-item-left-text{
        width: 100%;
        font-size: 0.775rem;
        color: #333;
        padding-top:0.3rem;
        padding-bottom:0.3rem;
        background-color: rgba($color: #fff, $alpha: 0.8);
        text-align: center;
        border-radius: 0.5rem;
      }
      .five-step-container-item-left-next{
        width: 5.25rem;
        height: 2rem;
        background-color: #E9E9E9;
        border: 1px solid #6E6E6E;
        border-radius: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.93rem;
        cursor: pointer;
        margin-top:1.75rem;
        img{
          width: 0.93rem;
          height: 0.93rem;
          margin-left: 0.5rem;
        }
      }
      .five-step-container-item-left-next-submit{
        width: 9.25rem;
        height: 2rem;
        background-color: #E9E9E9;
        border: 1px solid #6E6E6E;
        border-radius: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.93rem;
        cursor: pointer;
        margin-top:1.75rem;
        img{
          width: 0.93rem;
          height: 0.93rem;
          margin-left: 0.5rem;
        }
      }
      .active{
        background-color: #DDF5E1 !important;
        border: 1px solid #37A667 !important;
        color: #37A667 !important;
      }
    }
    .five-step-container-item-right{
      width: 50%;
      img{
        width: 100%;
      }
    }
  }
  
}