.backwardness-container{
  width: 100%;
  background-color: #fff;
  padding:35px 60px;
  .backwardness-container-item{
    width: 100%;
  }
}
.alert-info{
  width: 100%;
  padding: 8px 15px;
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
  margin-bottom: 20px;
}
.modal-container{
  width: 100%;
  height: 410px;
  overflow-y: auto;
}