
.login-head{
  .head{
    width:100%;
    height:56px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:0 130px;
    .logo{
      width: 43px;
      height: 43px;
    }
    .login{
      width: 108px;
      height: 56px;
      background-color: #11A96F;
      color: #fff;
      line-height: 56px;
      text-align: center;
      cursor: pointer;
    }
  }
}
.container,.container-login{
  width: 100%;
  height: 100vh;
  background-color: #fff;
  background: url('/assets/images/test-bg.jpg') no-repeat right;
  background-size: 74% 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .container-img{
    margin-left:140px;
  }
  .login-btn{
    width: 120px;
    height: 44px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #458755;
    margin-top:30px;
    span{
      color: #fff;
      font-size:24px;
    }
    img{
      height: 17.5px;
    }
  }
  .fais-title{
    font-size:43px;
    color: #458755;
    margin-top:20px;
    font-weight: 500;
  }
  .fais-introduce{
    color: #458755;
    font-size:20px;
    // font-weight: 500;
    margin-top:20px;
  }
  .container-list{
    width: 431px;
    height: 80%;
    background-color: rgba(247,247,249,0.8);
    box-shadow:0px 7px 16px 0px rgba(46,46,46,0.67);
    padding:32px 32px;
    .container-list-item{
      width: 367px;
      height: 10%;
      display: flex;
      color: #fff;
      font-size: 14px;
      align-items: center;
      justify-content: space-between;
      padding: 0 24px;
      .item-text{
        width: 211px;
      }
      .item-right-out{
        cursor: pointer;
        color: #ccc;
      }
    }
    .container-list-item + .container-list-item{
      margin-top:12px;
    }
  }
  .register-container{
    width: 440px;
    height: 520px;
    background-color: #fff;
    box-shadow:0px 3px 6px 0px rgba(94,94,94,0.44);
    border-radius:4px;
    .step-container{
      width: 100%;
      padding: 0 32px;
      margin-top:23px;
      .ant-steps-item-finish .ant-steps-item-icon{
        background-color: #fff;
        border:1px solid rgba(15,153,64,1);
      }
      .anticon{
        color:#0F9940;
      }
      .ant-steps-item-process .ant-steps-item-icon{
        background: #0F9940;
      }
    }
    .from-container{
      width: 100%;
      height: 340px;
      overflow-y: auto;
      margin-top:10px;
      border-bottom: 1px solid rgba(232,232,232,1);;
    }
    .foot{
      width: 100%;
      height: 56px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 32px;
      Button{
        margin-left:12px;
        height: 32px;
      }
      .clear{
        background-color: #ECECEC;
        border-color:#ECECEC;
        color:#4D4D4D;
      }
      .next{
        background-color: #0F9940;
        border-color:#0F9940;
        color:#fff;
      }
      .back{
        background-color: #FFFFFF;
        border-color:#D9D9D9;
        color:#4D4D4D;
      }
    }
    .register-container-title{
      width: 100%;
      height: 56px;
      line-height: 56px;
      font-size: 20px;
      color: #404040;
      padding:0 32px;
      border-bottom: 1px solid #e8e8e8;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .login-container-item{
      width: 100%;
      padding: 0 32px;
      margin-top:10px;
      span{
        font-size: 14px;
        color:#666;
      }
    }
    .login-container-item-button{
      width: 376px;
      height: 40px;
      margin: 32px 0 22px 32px;
      background-color: #0F9940;
      border-color:#0F9940;
    }
  }
}
.logo-btn{
  background-color: #0F9940 !important;
  color: #fff !important;
  font-size: 14px;
  border:1px solid #0F9940 !important;
  margin-bottom: 165px !important;
  margin-left:-270px !important;
  box-shadow:-3px 2px 2px rgba(48,51,49,0.44) !important;
}
.container-logo{
  width: 100%;
  display: flex;
  margin-top:30px;
  img{
    margin-right: 50px;
  }
}
.container-login{
  width: 100%;
  height: 765px;
  background: url('/assets/images/login_bg.jpg') no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lca-propaganda{
  width: 100%;
  height: 100vh;
  // background-color: #f8f8f8;
  // filter:opacity(80%);
  // background: url('/assets/images/bg2.jpg') no-repeat;
  background-size: 100% 100%;
  .register-container{
    width: 440px;
    height: 520px;
    background-color: #fff;
    box-shadow:0px 3px 6px 0px rgba(94,94,94,0.44);
    border-radius:4px;
    .step-container{
      width: 100%;
      padding: 0 32px;
      margin-top:23px;
      .ant-steps-item-finish .ant-steps-item-icon{
        background-color: #fff;
        border:1px solid rgba(15,153,64,1);
      }
      .anticon{
        color:#0F9940;
      }
      .ant-steps-item-process .ant-steps-item-icon{
        background: #0F9940;
      }
    }
    .from-container{
      width: 100%;
      height: 340px;
      overflow-y: auto;
      margin-top:10px;
      border-bottom: 1px solid rgba(232,232,232,1);;
    }
    .foot{
      width: 100%;
      height: 56px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 32px;
      Button{
        margin-left:12px;
        height: 32px;
      }
      .clear{
        background-color: #ECECEC;
        border-color:#ECECEC;
        color:#4D4D4D;
      }
      .next{
        background-color: #0F9940;
        border-color:#0F9940;
        color:#fff;
      }
      .back{
        background-color: #FFFFFF;
        border-color:#D9D9D9;
        color:#4D4D4D;
      }
    }
    .register-container-title{
      width: 100%;
      height: 56px;
      line-height: 56px;
      font-size: 20px;
      color: #404040;
      padding:0 32px;
      border-bottom: 1px solid #e8e8e8;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .login-container-item{
      width: 100%;
      padding: 0 32px;
      margin-top:10px;
      span{
        font-size: 14px;
        color:#666;
      }
    }
    .login-container-item-button{
      width: 376px;
      height: 40px;
      margin: 32px 0 22px 32px;
      background-color: #0F9940;
      border-color:#0F9940;
    }
  }
  .login-container{
    width: 350px;
    height: 400px;
    // box-shadow:0px 3px 6px 0px rgba(94,94,94,0.44);
    border-radius:4px;
    .ant-form-item{
      margin-bottom: 0 !important;
    }
    .login-title{
      width: 100%;
      font-size: 28px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 30px;
    }
    .login-steps{
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-bottom: 30px;
      .steps-active{
        font-weight: bold;
        color: #000;
      }
      .login-step-item{
        display: flex;
        align-items: center;
        .login-step-index{
          width: 25px;
          height: 25px;
          border-radius: 100%;
          text-align: center;
          line-height: 25px;
          background-color: #666666;
          color: #fff;
          font-size: 14px;
          margin-right: 10px;
        }
        span{
          font-size: 18px;
          color: #000;
        }
      }
      .login-divider{
        width: 31px;
        height: 2px;
        background-color: #3e3e3e;
      }
    }
    .login-button{
      width: 100%;
      height: 60px;
      line-height: 60px;
      background-color: #eee;
      color: #000;
      font-size: 28px;
      text-align: center;
      border-radius: 10px;
      margin-top:24px;
      margin-bottom: 10px;
      cursor: pointer;
    }
    .forget-password{
      width: 100%;
      text-align: right;
      font-size: 28px;
      padding:0 !important;
    }
    .login-register{
      width: 100%;
      text-align: right;
      font-size: 24px;
      span{
        color: #15772C;
        cursor: pointer;
      }
    }
    .register-button-container{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 10px;
      .register-button{
        width: 80px;
        height: 50px;
        line-height: 50px;
        font-size: 20px;
        color: #000;
        text-align: center;
        align-items: center;
        background-color: #eeeeee;
        border-radius: 10px;
        cursor: pointer;
      }
    }
    .login-item-container{
      width: 100%;
      height: auto;
      background-color: #eeeeee;
      border-radius: 10px;
      .login-item{
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        .login-item-divider{
          width: 4px;
          height: 30px;
          background: #3e3e3e;
          border-radius: 5px;
          margin-left:20px;
          margin-right:15px;
        }
        .login-item-input{
          width: 200px;
        }
        .login-item-input .ant-input,.ant-input-affix-wrapper{
          font-size: 16px;
          border:0px;
          background-color: #eeeeee !important;
        }
      }
      .login-item + .login-item {
        border-top:1px solid rgba(66,66,66,0.5);
      }
    }
    .login-container-item{
      width: 100%;
      padding: 0 32px;
      margin-top:10px;
      span{
        font-size: 14px;
        color:#666;
      }
    }
    .login-container-item-button{
      width: 376px;
      height: 40px;
      margin: 10px 0 22px 32px;
      background-color: #0F9940;
      border-color:#0F9940;
    }
    .login-container-title{
      width: 100%;
      height: 56px;
      line-height: 56px;
      font-size: 20px;
      color: #404040;
      padding:0 32px;
      border-bottom: 1px solid #e8e8e8;
    }
    .forget-password{
      width: 100%;
      padding: 0 32px;
      text-align: right;
      cursor: pointer;
    }
    .register{
      width: 100%;
      text-align: center;
      margin-top:10px;
      color: #8c8c8c;
      span{
        color:#00A566;
        cursor: pointer;
      }
    }
  }
}
.lca-propaganda::before{
  content: "";
  opacity: 0.4;
  background: url('/assets/images/bg2.jpg') no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: -1;
}
.fais-introduce-title{
  width: 100%;
  // height: 6.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #458755;
  font-size:2.375rem;
  padding: 2.5rem 3.75rem 0 3.75rem;
}
.fais-introduce-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top:62px;
  .fais-introduce-item{
    width: 400px;
    height: 40px;
    background: #458755;
    color: #fff;
    text-align: center;
    line-height: 40px;
    font-size:24px;
    font-weight: 500;
    box-shadow: 7px 10px 0px #35683F;
    margin-bottom: 25px;
  }
  .fais-introduce-item:nth-child(even){
    margin-left:80px;
  }
}