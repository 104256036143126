.conclusion-container{
  width: 100%;
  background-color: #fff;
  padding:23px 32px;
  .conclusion-tab{
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 14px;
    margin-bottom:20px;
    .conclusion-tab-item{
      padding:4px 20px;
      background-color: #fff;
      border:1px solid #aaa;
      border-radius: 40px;
      color: #AAA;
      cursor: pointer;
    }
    .conclusion-tab-item + .conclusion-tab-item{
      margin-left:16px;
    }
    .active {
      border-color: #2c80ff !important;
      color: #2c80ff !important;
      box-shadow: 0px 3px 6px 0px rgba(0, 41, 192, 0.17);
    }
    .disable-tab{
      cursor: no-drop !important;
    }
  }
  .form-title{
    font-weight: bold;
    font-size: 14px;
    color: #262626;
    margin-top:24px;
  }
  .form-content{
    padding-left:28px;
    .form-project-container{
      .form-project-container-item{
        width: 100%;
        border-bottom: 1px solid #F0F0F0;
        margin-top:20px;
        .form-project-container-item-head{
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 14px;
          color: #262626;
        }
        .form-project-container-item-body{
          padding:0px 5.3rem;
        }
      }
      .form-project-add-btn{
        border:1px solid #00a566 !important;
        color: #00a566 !important;
        background: #fff;
        margin-top:10px;
      }
    }
  }
  .form-btn-container{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .form-btn{
      background-color: #0F9940 !important;
      color: #fff !important;
      border: none;
    }
  }
}