.result-table-container{
  width: 100%;
  background-color: #fff;
  padding:24px 32px;
  .result-table-item{
    margin-bottom:20px;
    h3{
      font-weight: bold;
    }
  }
  
}