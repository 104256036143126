.white-container{
  width: 100%;
  height: 100%;
  background: #fff;
  overflow-y: auto;
}

.component-nav{
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #e8e8e8;
  padding: 0 24px;
  .nav-title{
    font-size: 14px;
    font-weight: bold;
  }
}

.nav-ul{
  display: inline-block;
  & li{
    display: inline-block;
    padding: 0 10px;
    cursor: pointer;
  }
  & .active {
    font-weight: bold;
    color: #0F9940 !important;
    border-bottom:1px solid #0F9940 !important;
    padding: 10px 0;
  }
  a{
    color: #333;
  }
}

.download-box{
  font-size: 16px;
  color: #0F9940;
  opacity: 0.8;
  position: fixed;
  top: 110px;
  left: 0;
  right: 0;
  z-index: 1000;
}
