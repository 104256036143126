.img-bg{
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.5);
  position: fixed;
  z-index: 1000000;
  display: flex;
  justify-content: center;
  align-items: center;
  .img-container{
    width: 60%;
    height: 80%;
    overflow-y: auto;
    img{
      width: 100%;
    }
  }
}
