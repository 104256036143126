.button-margin-left{
  Button{
    margin-left: 20px;
  }
}

.button-img{
  padding: 0px 4px 4px 0px;
}

button.ant-btn-background-ghost.ant-btn-primary{
  color: #00A968 !important;
  border-color: #00A968 !important;
}