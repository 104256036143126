.msg-container{
  .msg-congainer-title{
    color:#4d4d4d;
    font-size: 14px;
    font-weight: bold;
  }
  .msg-congainer-time{
    color: #4d4d4d;
    font-size: 12px;
    margin-top:0.75rem;
    text-indent: 0.5em;
  }
  .msg-congainer-content{
    color: #4d4d4d;
    font-size: 14px;
    margin-top:1.75rem;
  }
  .msg-container-body{
    margin-top:1.75rem;
    .msg-container-body-container{
      width: 100%;
      display: flex;
      border: 1px solid #d9d9d9;
      .msg-container-body-container-left{
        width: 30%;
        background: #F5F7F6;
        padding:10px 15px;
        border-right: 1px solid #d9d9d9;
      }
      .msg-container-body-container-right{
        width: 70%;
        background-color: #fff;
        padding:10px 15px;
      }
    }
    .msg-container-body-container + .msg-container-body-container{
      border-top:none;
    }
  }
  .msg-container-btn{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
    margin-top:2rem;
    .msg-btn-herf{
      height: 2rem;
      line-height: 2rem;
      text-align: center;
      color: #00a566;
      border: 1px solid #00a566;
      cursor: pointer;
      padding:0 12px;
    }
    .refuse{
      color:#d9534f !important;
      border:1px solid #d9534f !important;
      margin-left:0.75rem;
    }
    .msg-btn-close{
      width: 80px;
      height: 2rem;
      line-height: 2rem;
      text-align: center;
      border:1px solid #d9d9d9;
      cursor: pointer;
      margin-left:0.75rem;
    }
  }
  .refuse-title{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div:first-child{
      font-size: 14px;
      color: #4d4d4d;
      font-weight: bold;
    }
    .msg-btn-close{
      width: 80px;
      height: 2rem;
      line-height: 2rem;
      text-align: center;
      border:1px solid #d9d9d9;
      cursor: pointer;
      margin-left:0.75rem;
    }
  }
}