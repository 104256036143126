
.evaluate-detail-container{
  width: 100%;
  height: 100%;
  overflow-y: auto;
  color: #262626;
  padding:30px;
  background-color: #fff;
}

.card-div-title{
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  font-family: Microsoft YaHei UI;
  color: #262626;
  line-height: 24px;
  // width: 100%;
}

.font16{
  width: 100%;
  font-size: 16px;
}

.table-row-div{
  display: flex;
  align-items: center;
}
.table-bold{
  font-weight: bold;
}

.card-bais-box{
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 13px 0px rgba(46, 46, 46, 0.08);
  border-radius: 4px;
  padding: 16px 32px;
}

.font-Red{
  font-family: Microsoft YaHei UI;
  color: #D15757;
}

.item-title-divider{
  width: 3px;
  background: #262626;
  margin-left: 0px !important;
  display: inline-block;
}

.first-item{
  font-size: 12px;
  font-family: Microsoft YaHei UI;
  font-weight: bold;
  color: #333333;
}

.table-title{
  display: flex;
  align-items: center;
}
.hide{
  display: none;
}


.expert-testing-bold{
  color: #333333;
  font-size: 14px;
  font-weight: bold;
}
.expert-testing{
  color: #7B7B7B;

  font-weight: 400;
}

.ant-modal-body{
  max-height: 70vh;
  overflow: auto;
}