.enterprise-information{
  width: 100%;
  height: 100%;
  overflow-y: auto;
  color: #262626;
  font-size: 14px;
  padding:32px 32px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  .enterprise-information-item{
    width: 80%;
  }
  .row-bottom{
    margin-bottom: 40px;
  }
  .from-title{
    font-weight: bold;
  }
}