.echart-container{
  width: 100%;
  height: 26.75rem;
  overflow-y: auto;
  overflow-x: hidden;
  .echart-tag{
    padding:0 1rem;
    background:linear-gradient(-90deg, #26B57A, #5FD7A6);
    color: #fff;
    font-size: 0.75rem;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    height: 2rem;
    line-height: 2rem;
    display: inline-block;
    margin-bottom: 2rem;
  }
  .chart-container{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 2rem;
    
    .echart-item{
      width: 48%;
      height: 14rem;
      margin-bottom: 2rem;
      p{
        font-weight: bold;
        font-size: 0.875rem;
        color: #0d0d0d;
        text-align: center;
        margin-top:1rem;
      }
    }
    .echart-item:nth-child(even){
      margin-left: 4%;
    }
  }
  
}