.user-info{
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding:32px 40px;
  display: flex;
  justify-content: space-between;
  overflow-y: hidden;
  .user-info-container{
    width: 80%;
    overflow-y: auto;
    .user-info-container-item{
      width: 100%;
      padding-right:40px;
    }
  }
  .user-info-nav{
    width: 15%;
  }
}