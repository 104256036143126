.resource-query-container{
  display: flex;
  // justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 8px;

  .resource-query-head{
    width: 63.75rem;
    height: 3rem;
    display: flex;

    .resource-query-head-left{
      width: 80%;
      display: flex;
      align-items: flex-end;

      .head-left-text{
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .header-box1{
        position: relative;
        cursor: pointer;
        width: 123px;
      }
  
      .header-box2{
        position: relative;
        cursor: pointer;
        width: 123px;
        margin-left: -8px;
      }
    }

    .resource-query-head-right{
      width: 20%;
      text-align: right;
      margin: auto 0;
    }

  }

  .resource-query-content{
    width: 63.75rem;
    background:#fff;
    height: auto;
    overflow-y: auto;
  }
}