.enterprise-information{
    width: 100%;
    height: 100%;
    overflow-y: auto;
    color: #262626;
    font-size: 14px;
    padding:32px 32px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    .enterprise-information-item{
      width: 100%;
    }
    .row-bottom{
      margin-bottom: 40px;
    }
    .from-title{
      font-weight: bold;
    }
  }
   
  .popover-container{
    width: 250px;
  
    .popover-item{
      width: 100%;
      .popover-item-title{
  
      }
      .popover-item-content{
        padding-left: 22px;
      }
    }
  }
  
  .item-title-divider{
    width: 3px;
    background: #262626;
    margin-left: 0px !important;
    display: inline-block;
  }
  
  .item-title-p{
    display: inline-block;
    font-size: 14px;
    font-family: Microsoft YaHei UI;
    font-weight: bold;
    color: #262626;
    line-height: 24px;
    margin: 0px;
  }
  
  
  