.product-information-saving-information{
  width: 100%;
  height: 100%;
  overflow-y: auto;
  color: #262626;
  font-size: 14px;
  padding:32px 32px;
  background-color: #fff;
  .row-bottom{
    margin-bottom: 40px;
  }
  .ant-checkbox-disabled + span{
    color: #262626;
  }
}

.add-product-contanier{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  border-radius: 4px;
  .contanier-padding{
    text-align: center;
    padding: 8px;
    p{
      margin: 0;
    }
  }
}

.pane-title{
  font-size: 14px;
  font-family: Microsoft YaHei UI;
  font-weight: bold;
  color: #262626;
}

