.pane-title{
  font-size: 14px;
  font-family: Microsoft YaHei UI;
  font-weight: bold;
  color: #262626;
}
.contanier{
  position: relative;
}

.expert-info{
  position: absolute;
  z-index: 10;
  top: 0;
  left: 200px;
  height: 36px;
  display: flex;
  align-items: center;
  .expert-info-text{
    padding-left: 8px;
    font-size: 12px;
    font-family: Microsoft YaHei UI;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
  }
}

.expert-container{
  width: 100%;
  height: 100%;
  overflow-y: auto;
  color: #262626;
  padding:30px 50px 60px 50px;
  background-color: #fff;

  .expert-title{
    font-size: 24px;
    font-family: Microsoft YaHei UI;
    font-weight: 400;
    color: #262626;
    line-height: 24px;
    text-align: center;
  }

  .expert-content{
    box-shadow: 0px 0px 13px 0px rgba(46,46,46,0.0800);
    border-radius: 4px;
    padding: 30px;
  }
}