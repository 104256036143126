.diagnosis-list{
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding:32px 40px;
  overflow: auto;
  .diagnosis-list-status-item{
    width:6rem;
    height: 2rem;
    line-height: 2rem;
    background-color: #D4D9D8;
    text-align: center;
    color: #000;
    cursor: pointer;
  }
  .diagnosis-list-status-item-active{
    background-color: #000 !important;
    color: #fff !important;
  }
}
.expert-drawer-search{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.green-button{
  background-color: #0F9940 !important;
  color: #fff !important;
  font-size: 14px;
  border:1px solid #0F9940 !important;
}