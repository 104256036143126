.enterprise-information {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    color: #262626;
    font-size: 14px;
    padding: 32px 32px;
    background-color: #fff;
    display: flex;
    justify-content: center;

    .enterprise-information-item {
        width: 100%;

        .contentcard {
            padding: 33px;
            width: 100%;
            background: #FFFFFF;
            border: 1px solid #000;
            border-top: 3px solid #000;
            box-sizing: border-box;
            height: 400px;
            margin-bottom: 10px;

            .namebild {
                width: 50%;
                font-weight: bold;
                color: #5F5F5F;
            }

            .namevild1 {
                display: inline-block;
                width: 30%;
                line-height: 28px;
                text-indent: 30px;
                background: rgba(0, 169, 104, 0.2);
                color: rgba(0, 169, 104, 1);

            }

            &:hover {
                padding: 33px;
                box-sizing: border-box;
                width: 100%;
                background: #FFFFFF;
                border: 1px solid #00A968;
                border-top: 3px solid #00A968;
            }

        }
    }

    .row-bottom {
        margin-bottom: 40px;
    }

    .from-title {
        font-weight: bold;
    }

    .nowrap {

        white-space: nowrap;

    }
}