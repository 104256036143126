// 问答icon的基类
%quesion-list-item-icon{
  width: 1.25rem;
  height: 1.25rem;
  color: #fff;
  line-height: 1.25rem;
  text-align: center;
  border-right: 0.25rem;
  font-size: 0.75rem;
  margin-right: 1rem;
}

%question-list-item-questionAndAnswer{
  display:flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom:1.5rem;
}



.question-and-answer-container{
  display:flex;
  justify-content: center;
  .question-and-answer-content{
    width: 63.75rem;
    background:#fff;
    height: auto;
    overflow-y: auto;
    .question-and-answer-content-title{
      width: 100%;
      height: 3.25rem;
      border-bottom: 1px solid #e8e8e8;
      padding-left: 2rem;
      font-size: 0.875rem;
      color: #262626;
      font-weight: bold;
      line-height: 3.25rem;
    }
    .question-container{
      width: 100%;
      background-color: #fafafa;
      padding-top:1.68rem;
      padding-left:11.93rem;
      padding-bottom:0.625rem;
      display:flex;
      .question-btn-container{
        height: 2.625rem;
        display:flex;
        align-items: center;
        margin-top: 0.93rem;
        .question-btn{
          width: 6.25rem;
          height: 2.5rem;
          border-radius: 1.25rem;
          border:1px solid #26B57A;
          color: #26B57A;
          font-size: 1rem;
          text-align: center;
          line-height: 2.5rem;
          cursor: pointer;
        }
      }
    }
    .question-list-container{
      width:100%;
      padding: 2rem;
      background:#fff;
      .switch-status{
        .ant-badge-status-warning{
          top: 0.5rem;
          right: -0.3rem;
        }
        .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child{
          border-right-color:#4A9957;
        }
        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child{
          border-color: #4A9957;
        }
        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
          z-index: 1;
          color: #4A9957;
          background: #fff;
        }
        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
          z-index: 1;
          color: #4A9957;
          background: #fff;
          border-color: #4A9957;
        }
        .ant-radio-button-wrapper:hover{
          color: rgba(0,0,0,0.65);
        }
        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before{
          background-color: #4A9957;
        }
      }
      .question-list-search{
        width:25rem;;
        display:flex;
        margin-bottom: 2rem;
        .question-list-search-input{
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
          border-right: none;
          padding-right: 1rem;
        }
        .question-list-search-btn{
          margin-left:-1rem;
          background:#26B57A !important;
          border-color:#26B57A !important;
        }
      }
      .question-list{
        width: 100%;
        .question-list-item{
          width: 100%;
          padding-top:1.68rem;
          // padding-bottom:0.9rem;
          border-bottom: 1px solid #e8e8e8;
          .question-list-item-question{
            @extend %question-list-item-questionAndAnswer;
          }
          .question-list-item-answer{
            @extend %question-list-item-questionAndAnswer;
          }
          .quesion-list-item-question-icon{
            @extend %quesion-list-item-icon;
            background:#ffac3e;
          }
          .quesion-list-item-answer-icon{
            @extend %quesion-list-item-icon;
            background:#7ED07D;
          }
          .quesion-list-item-question-title{
            width: 45rem;
            font-size: 1rem;
            font-weight: bold;
            color: #333;
          }
          .quesion-list-item-answer-title{
            width: 45rem;
            font-size: 0.875rem;
            color: #333;
          }
          .quesion-list-item-time{
            font-size: 0.875rem;
            color: #999;
          }
        }
        .not-question-container{
          width: 100%;
          margin-bottom:1.5rem;
          .not-question-container-btn{
            width: 4.5rem;
            height: 2rem;
            border:1px solid #4a9957;
            color: #4a9957;
            border-radius: 1.25rem;
            font-size: 0.875rem;
            text-align: center;
            line-height: 2rem;
            cursor: pointer;
          }
          .not-question-submit-container{
            display:flex;
            justify-content: flex-end;
            align-items: center;
            margin-top:1rem;
          }
        }
      }
    }
  }
}


