.dyeing-container{
  width: 100%;
  background-color: #fff;
  padding:24px 32px;
  .dyeing-container-item{
    width: 100%;
    .dyeing-container-item-title{
      color:'#4d4d4d';
      font-size:15px;
      font-weight: bold;
    }
    .dyeing-container-item-body{
      width: 100%;
      padding:24px 50px;
      .item-title-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .item-title{
          display: flex;
          align-items: center;
          img{
            width: 14px;
            height: 14px;
            margin-right: 12px;
          }
          span{
            font-weight: bold;
            font-size: 14px;
            color: #272727;
          }
        }
        .change-open{
          cursor: pointer;
          span{
            margin-right: 5px;
          }
        }
      }
    }
  }
  .table-container{
    margin-top:10px;
    padding:0;
    padding-left:26px;
  }
  .ant-upload{
    width: 100%;
  }
}


.add-button{
  background: #fff !important;
  color: #00A566 !important;
  border-color: #00A566 !important;
}
.result{
  width: 100%;
  padding:0 16px 5px 16px;
  border:1px solid #D9D9D9;
  .result-item{
    width: 100%;
    height: 40px;
    padding:0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom:1px solid #F0F0F0;
    div{
      width: 150px;
      text-align: right;
    }
    div:nth-child(1){
      text-align: left;
    }
  }
  .result-foot{
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding:0 15px;
    text-align: right;
    span{
      color: #BC4C4C;
    }
  }
}