.water-effect-assess-container {
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding:32px 40px;
    overflow: auto;
  }
  .ellipsis-text{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 200px;
    display: block;
    cursor: pointer;
  }
  