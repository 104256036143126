.diagnosis-list{
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding:32px 40px;
  overflow: auto;
  .ant-checkbox-group-item{
    width: 40%;
    margin-top:(23 / 16) + rem;
  }
  .export-buttom{
    padding:(10/16)+rem (30/16)+rem;
    background: #00A566;
    color: #fff;
    font-size: (14/16)+rem;
    cursor: pointer;
  }
}