.agreement-container{
  width: 100%;
  height:22rem;
  overflow-y: auto;
  .agreement-body{
    padding:1rem 1rem;
    font-size: 1rem;
    .agreement-title{
      text-align: center;
      font-weight: bold;
      margin-bottom: 1rem;
    }
    .agreement-dont-weight{
      text-indent:2em;
      margin-bottom: 1rem;
    }
    .agreement-weight{
      font-weight: bold;
      margin-bottom: 1rem;
    }
    .agreement-ui-title{
      font-weight: bold;
      margin-bottom: 1rem;
    }
    .agreement-ui-body{
      margin-bottom: 1rem;
      ul{
        margin-bottom: 0;
      }
    }
    .agreement-ui-body-title{
      font-weight: bold;
      span{
        font-weight: 400;
      }
    }
  }
  .agree-btn{
    width: 100%;
    display: flex;
    justify-content: center;
  }
}