.enterprise-technology{
  width: 100%;
  background-color: #fff;
  padding:32px 32px;
  margin-bottom: 30px;
  .enterprise-technology-item{
    width: 100%;
    margin-bottom:20px;
    .enterprise-technology-item-head{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .enterprise-technology-item-left{
        color: #737373;
        font-weight: bold;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        width: 30%;
        margin-bottom:10px;
      }
    }
  }
}
.system-boundary-item-head-name{
  height: 40px;
  display: flex;
  align-items: center;
  font-weight: bold;
}